<template lang="html">
    <section class="news">
        <section class="container">
            <template v-if="news">
                <section class="container-grid">
                    <div class="grid-big">
                        <template v-for="(notice, key, index) in news" :key="key">
                            <newFullImg v-if="index == 0" :dataContent="notice"></newFullImg>
                        </template>
                    </div>
                    <div class="grid-small">
                        <template v-for="(notice, key, index) in news" :key="key">
                            <newVerticalImg v-if="index == 1" :dataContent="notice"></newVerticalImg>
                        </template>
                    </div>
                </section>
                <section class="grid-3">
                    <template v-for="(notice, key, index) in news" :key="key">
                        <newVerticalImg v-if="index > 1" :dataContent="notice"></newVerticalImg>
                    </template>
                </section>
                <paginator :pageActual="page" @changed="changePages" :totalItems="NewsMaxItems" @clicked="changePages" ></paginator>
            </template>
            <div v-else class="container-noResult">
                <p class="title-dest-underline">No hay resultados</p>
            </div>
            <!-- <gridContentsFooter></gridContentsFooter> -->
        </section>
    </section>
</template>

<script lang="js">
import {
    mapGetters,
    mapActions,
    mapMutations
} from 'vuex';
import store from '@/store';
import newFullImg from "@/components/news/new-full-img.vue";
import newVerticalImg from "@/components/news/new-featured-vertical-img.vue";
import paginator from "@/components/paginator.vue";
import utils from "@/utils/utils.js";
export default {
    name: 'news',
    components: {
        newFullImg,
        newVerticalImg,
        paginator
    },
    props: ['filterTime'],
    computed: {
        ...mapGetters({
            getPagesNews: 'contents/getPagesNews',
            page: 'contents/getNewViewPage'
        }),
        news: function () {
            return store.getters['contents/getNews'](this.page)
        },
        NewsMaxItems() {
            return this.getPagesNews();
        }
    },
    methods: {
        ...mapActions({
            loadNews: 'contents/loadNews'
        }),
        ...mapMutations({
            setPageNew: 'contents/setPageNew',

        }),
        changePages(item) {
            this.setPageNew(item)
            this.loadNews({
                page: this.page,
            });
            utils.LoaderScrollToTop();
        }
    },
    mounted() {
        this.loadNews();
    },
    watch: {
        filterTime: function (newVal, oldVal) {
            this.loadNews({ page: 1 });
        }
    }
}
</script>
<style scoped lang="scss">
    .news{
        .new-featured-vertical-img,
        .new-full-img .info-box{
            border: 0;
        }
    }
</style>